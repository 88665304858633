(function () {
    const menuItems = document.querySelectorAll("#sidenav nav > ul > li, .sidenav-bottom > ul > li");

    Array.prototype.forEach.call(menuItems, (menuItem) => {
        const aEl = menuItem.querySelector("a");

        if (!aEl) {
            // Skip separators
            return;
        }

        const subNavEl = aEl.parentNode.querySelector(".subnav-list");

        // Automatically close the sub nav when navigating to the next menu
        // item with keyboard
        aEl.addEventListener("focus", () => {
            const alreadyOpenSubNav = document.querySelector(".subnav--open");

            if (alreadyOpenSubNav && alreadyOpenSubNav !== aEl) {
                closeSubNav(alreadyOpenSubNav);
            }
        });

        if (!subNavEl) {
            // Only continue for items with a sub nav
            return;
        }

        const subNavNeedsFilter =
            subNavEl.childNodes.length > 10 && !subNavEl.classList.contains("subnav-list--no-filter");

        // Prevent the sub nav container from taking focus during keyboard
        // navigation
        subNavEl.tabIndex = -1;

        if (subNavNeedsFilter) {
            addSubNavFilter(subNavEl);
        }

        if (!aEl.href) {
            // Set an empty href so that the sub nav can be reached with
            // keyboard controls
            aEl.href = "";
        }

        // Show the sub nav with normal mouse use
        menuItem.addEventListener("mouseenter", () => {
            openSubNav(aEl, subNavNeedsFilter);
        });

        // Hide the sub nav with normal mouse use. Only hide after a short delay to help people
        // with poor motor skills
        menuItem.addEventListener("mouseleave", () => {
            closeSubNavTimer = setTimeout(() => {
                closeSubNav(aEl, subNavNeedsFilter);
            }, 300);
        });

        // Open the sub nav with a keyboard or touch device
        aEl.addEventListener("click", (e) => {
            if (aEl.classList.contains("subnav--open")) {
                closeSubNav(aEl, subNavNeedsFilter);
            } else {
                openSubNav(aEl, subNavNeedsFilter);
            }

            e.preventDefault();
        });
    });

    let previousFocus;
    let closeSubNavTimer;

    function openSubNav(subNavEl, hasFilter) {
        // Close any sub navs that are already open
        Array.prototype.forEach.call(document.querySelectorAll(".subnav--open"), (openNav) => {
            openNav.classList.remove("subnav--open");
        });

        subNavEl.classList.add("subnav--open");
        clearTimeout(closeSubNavTimer);

        if (hasFilter) {
            // Automatically focus on the sub nav filter, preserving the
            // previously-focused element
            previousFocus = document.activeElement || document.body;
            requestAnimationFrame(() => {
                subNavEl.parentNode.querySelector(".subnav-list__filter").focus();
            });
        }
    }

    function closeSubNav(subNavEl, hasFilter) {
        subNavEl.classList.remove("subnav--open");

        if (hasFilter && previousFocus) {
            previousFocus.focus();
        }
    }

    function addSubNavFilter(subNav) {
        const filterItem = document.createElement("li");
        filterItem.innerHTML = '<input type="text" class="subnav-list__filter" placeholder="Start typing to filter">';

        const searchInput = filterItem.querySelector(".subnav-list__filter");
        searchInput.addEventListener("input", () => {
            const filterVal = searchInput.value;

            subNav.childNodes.forEach((li) => {
                if (li === filterItem) {
                    return;
                }

                if (!filterVal.length || li.textContent.toLowerCase().includes(filterVal.toLowerCase())) {
                    li.style.display = "block";
                } else {
                    li.style.display = "none";
                }
            });
        });

        subNav.insertBefore(filterItem, subNav.firstChild);
    }
})();
