import clickableRow from "./clickable-row";

performance.mark("speedcurvejs before");

// We can't use require("jquery") here because our webpack config aliases jquery
// to window.jQuery. This is the file that actually sets window.jQuery, so to get
// around the webpack alias, we require the full path to jquery.js.
window.$ = window.jQuery = require("jquery/dist/jquery");

const webVitals = require("web-vitals");

require("./vendor/bootstrap-3-3-7-custom");
const LV = require("./vendor/livevalidation-1.3");
window.tagsInput = require("./vendor/tags-input");
window.loadCSS = require("./vendor/loadCSS").loadCSS;
require("./vendor/cssrelpreload");
require("./sidenav");

const getNodeSelector = (node, selector = "", firstOnly = false) => {
    if (!(node instanceof Element)) {
        return undefined;
    }

    const delimiter = ">";
    try {
        if (selector && (node.nodeType === 9 || selector.length > 100 || !node.parentNode)) {
            // Final selector.
            return selector;
        } else if (node.id) {
            // Once we've found an element with ID we return the selector.
            return "#" + node.id + (selector ? delimiter + selector : "");
        } else {
            // Otherwise attempt to get parent elements recursively
            const name = node.nodeType === 1 ? node.nodeName.toLowerCase() : node.nodeName.toUpperCase();
            const classes = node.className ? "." + node.className.replace(" ", ".") : "";
            const currentSelector = name + classes + (selector ? delimiter + selector : "");
            selector = firstOnly
                ? currentSelector
                : node.parentNode
                  ? getNodeSelector(node.parentNode, currentSelector)
                  : currentSelector;
        }
    } catch (error) {
        return undefined;
    }

    return selector;
};

// Begin web vitals
// We collect metrics from the official web-vitals library as custom RUM data so that we can compare
// them with the metrics we collect in SpeedCurve RUM.
const recordWebVital = (record) => {
    const { name, value } = record;
    if (window.LUX) {
        const precision = name === "CLS" ? 5 : 1;
        if (name === "LCP") {
            // Record LCP attribution.
            if ("entries" in record && record.entries.length) {
                const lcp = record.entries[0];
                if ("element" in lcp && lcp.element) {
                    const lcpNode = lcp.element;
                    if (lcpNode instanceof Element) {
                        const lcpElementName = lcpNode.nodeName.toLowerCase();
                        const lcpElementNodeType = lcpNode.nodeType;
                        window.LUX.addData("LCP-element", getNodeSelector(lcpNode, "", true));
                        window.LUX.addData("LCP-element-path", getNodeSelector(lcpNode));
                        window.LUX.addData("LCP-node-name", lcpElementName);
                        window.LUX.addData("LCP-node-type", lcpElementNodeType);
                        window.LUX.addData(
                            "LCP-type",
                            lcpElementNodeType === 3 ? "text" : lcpElementName === "img" ? "image" : "other",
                        );
                        if (lcpNode.tagName === "IMG") {
                            window.LUX.addData("LCP-src", lcpNode.currentSrc);
                        } else if (lcpNode.innerText) {
                            const lcpText = lcpNode.innerText;
                            window.LUX.addData(
                                "LCP-text",
                                lcpText && lcpText.length > 100 ? lcpText.slice(0, 100) : lcpText,
                            );
                        }
                    }
                }
            }
        } else if (name === "INP") {
            // Record INP attribution.
            if ("entries" in record && record.entries.length) {
                const inp = record.entries[0];
                const inpNode = inp.target;
                if (inpNode instanceof Element) {
                    window.LUX.addData("INP-element", getNodeSelector(inpNode, "", true));
                    window.LUX.addData("INP-element-path", getNodeSelector(inpNode));
                    window.LUX.addData("INP-node-name", inpNode.nodeName.toLowerCase());
                }
            }
        }
        window.LUX.addData(`web-vitals-${name}`, value.toFixed(precision));
    }
};

webVitals.onCLS(recordWebVital, true);
webVitals.onFCP(recordWebVital, true);
webVitals.onFID(recordWebVital, true);
webVitals.onINP(recordWebVital, true);
webVitals.onLCP(recordWebVital, true);
webVitals.onTTFB(recordWebVital, true);
// End web vitals

window.LiveValidation = LV.LiveValidation;
window.Validate = LV.Validate;

$(document).ready(function () {
    $("#sidenav-hamburger").click(function () {
        $("#sidenav").toggleClass("sidenav--open");
    });

    $("#sidenav .subnav").click(function () {
        if ($(window).width() < 768) {
            $(this).parent().children(".subnav-list").toggle();
        }
    });

    document.querySelectorAll(".clickrow").forEach((row) => clickableRow(row));
});

window.createEventForUser = function (data) {
    navigator.sendBeacon("/event/", JSON.stringify(data));
};

performance.measure("speedcurvejs eval", "speedcurvejs before");
